import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import LinkBack from "../components/link-back"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Seite nicht gefunden" />
    <section className="h-100">
      <Container className="text-center">
        <h1>404 Fault!</h1>
        <p>Die Seite konnte nicht gefunden werden...</p>
        <p><LinkBack to="/" label={'Homepage'} /></p>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
